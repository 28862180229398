import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Mari Erkvania - UX/UI Designer</h1>
      <h2>UX/UI Designer based in Toronto, Canada.</h2>
      <p>The page is under construction</p>
    </div>
  );
}

export default App;
